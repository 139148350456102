const scrollToElement = (elem: HTMLElement): void => {
  if (elem === null) return

  let yOffset = 0
  if (window.innerWidth < 992) {
    yOffset = -60
  } else {
    yOffset = -56
  }

  const y: number = elem.getBoundingClientRect().top + window.scrollY + yOffset
  window.scrollTo({ top: y, behavior: 'smooth' })
}

export { scrollToElement }
