import { RMSVerticalSpacingBlockModel } from './RMSVerticalSpacingBlockModel'
import styles from './RMSVerticalSpacingBlock.module.scss'
import { DownloadElement } from '../../components/elements/download-element/DownloadElement'

export default function RMSVerticalSpacingBlock({
  blockElement,
}: {
  blockElement: RMSVerticalSpacingBlockModel
}) {
  const data = blockElement.value
  return (
    <div
      className={styles.RMSVerticalSpacingBlock}
      style={{
        marginTop: `${data.top_space ?? 0}px`,
        marginBottom: `${data.bottom_space ?? 0}px`,
      }}
    >
      {data.use_seperator && (
        <div
          className={styles.seperator}
          style={{
            backgroundColor: data.seperator_color ?? 'transparent',
            borderRadius: '8px',
            width: data.seperator_width ?? '100%',
            height: data.seperator_height ?? '100%',
            paddingLeft: `${data.seperator_left_space ?? 0}px`,
            paddingRight: `${data.seperator_right_space ?? 0}px`,
          }}
        ></div>
      )}
    </div>
  )
}
