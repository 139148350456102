import { RMSContactBlockModel } from '../../../../features/rms-contact/blocks/rms-contact-block/RMSContactBlockModel'
import RMSContactBlock from '../../../../features/rms-contact/blocks/rms-contact-block/RMSContactBlock'
import {
  createRMSComponentTransformerObject,
  Tag,
} from '../../../../features/rms-component-transformer/rms-component-transformer'
import React, { ReactElement } from 'react'
import { motion } from 'framer-motion'
import {
  configOpacitySlideRight,
  configOpacitySlideUp,
} from '../../animations/framer-motion/motion-configs'

export default function ContactBlock({
  blockElement,
}: {
  blockElement: RMSContactBlockModel
}) {
  return (
    <RMSContactBlock
      blockElement={blockElement}
      htmlComponentTransformers={{
        headerElement: createRMSComponentTransformerObject(
          (tag: Tag): React.FC<any> => {
            return ({ children, ...props }: any): ReactElement => {
              const Component = motion[tag] as React.FC<any>
              return (
                <Component {...props} {...configOpacitySlideUp}>
                  {children}
                </Component>
              )
            }
          },
        ),
        contentElement: createRMSComponentTransformerObject(
          (tag: Tag): React.FC<any> => {
            return ({ children, ...props }: any): ReactElement => {
              const Component = motion[tag] as React.FC<any>
              return (
                <Component {...props} {...configOpacitySlideRight}>
                  {children}
                </Component>
              )
            }
          },
        ),
      }}
    />
  )
}
