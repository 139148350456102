import { createReducer } from '@reduxjs/toolkit'
import { RMSApiContainer } from '../../api/models/RMSApiContainer'
import ContactRequest from '../models/ContactRequest'
import { contactRequestReducerLogic } from './contactReducerLogic'

export const rmsContactInitialState: {
  contactRequest?: RMSApiContainer<ContactRequest>
} = {
  contactRequest: undefined,
}

export const rmsContactReducer = createReducer(
  rmsContactInitialState,
  builder => {
    contactRequestReducerLogic(builder)
  },
)
