import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './RMSBadge.module.scss'

interface IProps {
  to: string
  children: any
}

export default function RMSBadge(props: IProps): React.JSX.Element {
  return (
    <Link to={props.to} className={styles.badge}>
      {props.children}
    </Link>
  )
}
