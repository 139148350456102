import { inspect } from 'util'
import styles from './RMSDialog.module.scss'
import { ReactNode } from 'react'
import multipleClassName from '../../../../utils/multipleClassName'

type RMSDialogProps = {
  children: ReactNode
  onClickBackground?: (event: React.MouseEvent) => void
  startClose?: boolean
}

export default function RMSDialog({
  children,
  onClickBackground,
  startClose,
}: RMSDialogProps) {
  return (
    <div
      className={multipleClassName(
        styles.RMSDialog,
        startClose ? styles.close : '',
      )}
    >
      <div
        className={styles.RMSDialog__Background}
        onClick={event => {
          onClickBackground && onClickBackground(event)
        }}
      ></div>
      <div className={styles.RMSDialog__Content}>{children}</div>
    </div>
  )
}
