import { RMSPagePreview } from '../../../pages/RMSPage/RMSPageModel'
import styles from './RMSPagePreviewElement.module.scss'
import rmsCmsImageUrl from '../../../../../features/cms/utils/rmsCmsImageUrl'
import multipleClassName from '../../../../../utils/multipleClassName'
import { Link } from 'react-router-dom'
import RMSCard from '../rms-card/RMSCard'
import { configOpacitySlideUp } from '../../../animations/framer-motion/motion-configs'
import { motion } from 'framer-motion'

export default function RMSPagePreviewElement({
  pagePreview,
}: {
  pagePreview: RMSPagePreview
}) {
  return (
    <>
      <motion.div
        {...configOpacitySlideUp}
        className={styles.RMSPagePreviewElement}
      >
        <RMSCard
          image={rmsCmsImageUrl(pagePreview.title_picture ?? '')}
          title={pagePreview.title ?? ''}
          text={pagePreview.preview ?? ''}
          buttonText="Weiterlesen"
          to={pagePreview.url ?? ''}
        />
      </motion.div>
    </>
  )
}
