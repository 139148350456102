export default interface RMSApiConfig {
  baseURL?: string
  requestInterceptors?: (
    data: any,
    headers: any,
  ) => any | [(data: any, headers: any) => any]
  responseInterceptors?: (data: any) => any | [(data: any) => any]
  headers?: any
  params?: any
  data?: any
  timeout?: number //milliseconds
  beforeRedirect?: (
    options: Record<string, any>,
    responseDetails: { headers: Record<string, string> },
  ) => void
}

export const defaultRMSApiConfig: RMSApiConfig = {
  timeout: 10000,
}
