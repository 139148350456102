class RmsId {
  private static id = 1

  static generate() {
    return `rms-id-${RmsId.id++}`
  }
}

const useRmsId = () => {
  return RmsId.generate()
}

export { useRmsId }
