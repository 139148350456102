import { DHRichTextBlockModel } from './DHRichTextBlockModel'
import styles from './DHRichTextBlock.module.scss'
import DHRichTextElement from '../../components/elements/dh-rich-text-element/DHRichTextElement'

export default function DHRichTextBlock({
  blockElement,
}: {
  blockElement: DHRichTextBlockModel
}) {
  return <DHRichTextElement dhRichText={blockElement.value} />
}
