// store.ts
import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { rmsCmsReducer } from '../features/cms/reducers/rmsCmsReducer'
import { rmsContactReducer } from '../features/rms-contact/reducers/rmsContactReducer'
import { lightboxSlice } from './redux/slices/lightboxSlice'
import { rmsStatusDialogReducer } from '../features/rms-status-dialog/reducers/rmsStatusDialogReducer'
import { dialogsSlice } from './redux/slices/dialogsSlice'

export interface RootState {
  api: ReturnType<typeof apiReducer>
  lightbox: ReturnType<typeof lightboxSlice.reducer>
  dialogs: ReturnType<typeof dialogsSlice.reducer>
}

const apiReducer = combineReducers({
  cms: rmsCmsReducer,
  contact: rmsContactReducer,
  statusDialogs: rmsStatusDialogReducer,
})

const rootReducer = combineReducers({
  api: apiReducer,
  lightbox: lightboxSlice.reducer,
  dialogs: dialogsSlice.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
})

export default store
