import {
  ActionReducerMapBuilder,
  AsyncThunk,
  AsyncThunkAction,
} from '@reduxjs/toolkit'
import { LoadingState } from '../models/LoadingState'
import { RMSApiContainer } from '../models/RMSApiContainer'

const createAPIActionReducerLogic = <
  ActionType extends AsyncThunk<any, any, any>,
  ReducerState,
>(
  builder: ActionReducerMapBuilder<any>,
  action: ActionType,
  reducerState: (state: ReducerState) => any,
  updateReducerState: (state: ReducerState, newValue: any) => void,
) => {
  builder.addCase(action.pending, (state, action) => {
    let currentState = reducerState(state)

    if (!currentState) {
      updateReducerState(state, {
        loadingState: LoadingState.Loading,
      })
    } else {
      currentState.loadingState = LoadingState.Loading
    }
  })
  builder.addCase(action.fulfilled, (state, action) => {
    let currentState = reducerState(state)

    if (currentState) {
      currentState.payload = action.payload
      currentState.fetchedAtTimestamp = new Date().getTime()
      currentState.loadingState = LoadingState.Success
    }
  })
  builder.addCase(action.rejected, (state, action) => {
    let currentState = reducerState(state)
    if (currentState) {
      currentState.fetchedAtTimestamp = new Date().getTime()
      currentState.loadingState = LoadingState.Error

      if (!currentState.error) {
        currentState.error = {
          message: (action.error as any).message,
          statusCode: (action.error as any).code,
          errorObject: action.error,
        }
      }
    }
  })
}

export default createAPIActionReducerLogic
