import multipleClassName from '../../../../utils/multipleClassName'
import styles from './RMSContactBlock.module.scss'
import transformToReactStyle, {
  transformToReactMaxWidthContainerStyle,
} from '../../../../main/elements/components/elements/rms-css-style-block/transformToReactStyle'
import transformToReactContainerClasses from '../../../../main/elements/components/elements/rms-css-style-block/transformToReactClasses'
import { RMSContactBlockModel } from './RMSContactBlockModel'
import { defaultTransformerObject } from '../../../rms-component-transformer/rms-component-transformer'
import { useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { CircularProgress, TextField } from '@mui/material'
import { useApiContainer } from '../../../api/hooks/useApiContainer'
import { RootState } from '../../../../main/store'
import ContactRequest from '../../models/ContactRequest'
import { useDispatch } from 'react-redux'
import { useRMSApiConfig } from '../../../api/contexts/RMSApiContext'
import { createContactRequest } from '../../actions/createContactRequest'
import { LoadingState } from '../../../api/models/LoadingState'
import RMSEmployeeProfilePicture from '../../../rms-projects/features/employee/components/rms-employee-profile-picture/RMSEmployeeProfilePicture'
import ContactElement from '../../../rms-projects/features/general/components/elements/contact-element/ContactElement'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { configOpacitySlideUp } from '../../../../main/elements/animations/framer-motion/motion-configs'
import { BeatLoader } from 'react-spinners'
import parse from 'html-react-parser'

export default function RMSContactBlock({
  blockElement,
  htmlComponentTransformers,
}: {
  blockElement: RMSContactBlockModel
  htmlComponentTransformers?: {
    headerElement?: typeof defaultTransformerObject
    contentElement?: typeof defaultTransformerObject
  }
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const dispatch = useDispatch()
  const apiConfig = useRMSApiConfig()
  const contactRequestAPI = useApiContainer<RootState, ContactRequest>(
    (state: RootState) => state.api.contact.contactRequest,
  )

  const data = blockElement.value
  const headerTransformer =
    htmlComponentTransformers?.headerElement ?? defaultTransformerObject
  const contentTransformer =
    htmlComponentTransformers?.contentElement ?? defaultTransformerObject

  useEffect(() => {
    if (contactRequestAPI?.loadingState === LoadingState.Success) {
      reset()
    }
  }, [contactRequestAPI?.loadingState])

  const onSubmit = (data: ContactRequest) => {
    dispatch(
      createContactRequest({
        contactRequest: data,
        apiConfig: apiConfig,
      }) as any,
    )
  }

  const employee = blockElement.value.employee

  return (
    <section
      className={classNames(
        styles.section,
        blockElement.value.css_style?.additional_classes,
      )}
      style={transformToReactStyle(blockElement.value.css_style)}
    >
      <div className={styles.content}>
        {/* Title */}
        <div className={classNames(styles.inner)}>
          {blockElement.value.title && (
            <motion.h2 {...configOpacitySlideUp} className={styles.title}>
              {blockElement.value.title}
            </motion.h2>
          )}

          {/* Subtitle */}
          {blockElement.value.subtitle && (
            <motion.p
              {...configOpacitySlideUp}
              transition={{
                ...configOpacitySlideUp.transition,
                delay: 0.2,
              }}
              className={styles.subtitle}
            >
              {blockElement.value.subtitle}
            </motion.p>
          )}

          {/* Content */}
          {blockElement.value.content && (
            <motion.p
              {...configOpacitySlideUp}
              transition={{
                ...configOpacitySlideUp.transition,
                delay: 0.2,
              }}
              className={styles.subtitle}
            >
              {parse(blockElement.value.content)}
            </motion.p>
          )}

          {/* Form */}
          <form
            className={styles.form}
            onSubmit={handleSubmit(data => onSubmit(data as any))}
          >
            {contactRequestAPI?.loadingState !== LoadingState.Success && (
              <>
                <motion.div
                  {...configOpacitySlideUp}
                  transition={{
                    ...configOpacitySlideUp.transition,
                    delay: 0.4,
                  }}
                  className={styles.element}
                >
                  <TextField
                    className={styles.input}
                    {...register('first_name', {
                      required: {
                        value: true,
                        message: 'Bitte geben Sie Ihren Vornamen ein.',
                      },
                    })}
                    size="small"
                    id="outlined-basic"
                    label="Vorname"
                    variant="outlined"
                    required={true}
                    error={!!errors.first_name}
                    helperText={
                      errors.first_name && (errors.first_name.message as string)
                    }
                  />
                </motion.div>

                <motion.div
                  {...configOpacitySlideUp}
                  transition={{
                    ...configOpacitySlideUp.transition,
                    delay: 0.4,
                  }}
                  className={styles.element}
                >
                  <TextField
                    className={styles.input}
                    {...register('last_name', {
                      required: {
                        value: true,
                        message: 'Bitte geben Sie Ihren Nachnamen ein.',
                      },
                    })}
                    size="small"
                    id="outlined-basic"
                    label="Nachname"
                    variant="outlined"
                    required={true}
                    error={!!errors.last_name}
                    helperText={
                      errors.last_name && (errors.last_name.message as string)
                    }
                  />
                </motion.div>

                <motion.div
                  {...configOpacitySlideUp}
                  transition={{
                    ...configOpacitySlideUp.transition,
                    delay: 0.5,
                  }}
                  className={styles.element}
                >
                  <TextField
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'Bitte geben Sie eine Email-Adresse ein.',
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message:
                          'Falsches Format. Bitte geben Sie eine gültige Email-Adresse ein.',
                      },
                    })}
                    className={styles.input}
                    error={!!errors.email}
                    helperText={
                      errors.email && (errors.email.message as string)
                    }
                    size="small"
                    required={true}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                  />
                </motion.div>

                <motion.div
                  {...configOpacitySlideUp}
                  transition={{
                    ...configOpacitySlideUp.transition,
                    delay: 0.5,
                  }}
                  className={styles.element}
                >
                  <TextField
                    {...register('phone', { required: false })}
                    size="small"
                    id="outlined-basic"
                    label="Telefon"
                    variant="outlined"
                    className={styles.input}
                  />
                </motion.div>

                <motion.div
                  {...configOpacitySlideUp}
                  transition={{
                    ...configOpacitySlideUp.transition,
                    delay: 0.6,
                  }}
                  className={styles.messageField}
                >
                  <TextField
                    {...register('message', { required: true })}
                    error={!!errors.message}
                    helperText={
                      errors.message && 'Bitte geben Sie eine Nachricht ein.'
                    }
                    size="small"
                    required={true}
                    multiline
                    minRows={5}
                    id="outlined-basic"
                    label="Nachricht"
                    variant="outlined"
                    className={styles.input}
                  />
                </motion.div>
              </>
            )}

            <motion.div
              className={styles.bottom}
              {...configOpacitySlideUp}
              transition={{
                ...configOpacitySlideUp.transition,
                delay: 0.6,
              }}
            >
              {contactRequestAPI?.loadingState == LoadingState.Error && (
                <div className={styles.error}>
                  Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später
                  erneut.
                </div>
              )}
              {contactRequestAPI?.loadingState == LoadingState.Success && (
                <div className={styles.success}>
                  Vielen Dank für Ihre Kontaktanfrage. Wir werden uns in Kürze
                  bei Ihnen melden.
                </div>
              )}
              {contactRequestAPI?.loadingState === LoadingState.Loading && (
                <div className={styles.loaderContainer}>
                  <BeatLoader
                    color={'var(--rms-color-primary-700)'}
                    loading={true}
                    size={25}
                    speedMultiplier={2}
                  />
                </div>
              )}
              {contactRequestAPI?.loadingState != LoadingState.Loading &&
                contactRequestAPI?.loadingState != LoadingState.Success &&
                contactRequestAPI?.loadingState != LoadingState.Error && (
                  <button
                    type="submit"
                    className={classNames('primary', styles.sendButton)}
                  >
                    Senden
                  </button>
                )}
            </motion.div>
          </form>
        </div>
      </div>
    </section>
  )
}
