import React, { useEffect } from 'react'
import { RMSTestimonialsBlockModel } from './RMSTestimonialsBlockModel'
import styles from './RMSTestimonialsBlock.module.scss'
import classNames from 'classnames'
import transformToReactStyle from '../../components/elements/rms-css-style-block/transformToReactStyle'
import { motion } from 'framer-motion'
import { configOpacitySlideUp } from '../../animations/framer-motion/motion-configs'
import RMSTestimonialCard from '../../../../features/rms-projects/features/testimonials/components/RMSTestimonialCard'
import { Link } from 'react-router-dom'

export default function RMSTestimonialsBlock({
  blockElement,
}: {
  blockElement: RMSTestimonialsBlockModel
}): React.JSX.Element {
  return (
    <section
      className={classNames(
        styles.section,
        blockElement.value.css_style?.additional_classes,
      )}
      style={transformToReactStyle(blockElement.value.css_style)}
    >
      <div className={styles.content}>
        <div className={classNames(styles.inner)}>
          {blockElement.value.title && (
            <motion.h2 {...configOpacitySlideUp} className={styles.title}>
              {blockElement.value.title}
            </motion.h2>
          )}

          {blockElement.value.subtitle && (
            <motion.p
              {...configOpacitySlideUp}
              transition={{
                ...configOpacitySlideUp.transition,
                delay: 0.2,
              }}
              className={styles.subtitle}
            >
              {blockElement.value.subtitle}
            </motion.p>
          )}

          <div className={classNames(styles.testimonials)}>
            {blockElement.value.testimonials?.map((testimonial, index) => (
              <motion.div
                key={index}
                className={classNames(styles.testimonial, 'max-w-60')}
                {...configOpacitySlideUp}
                transition={{
                  ...configOpacitySlideUp.transition,
                  delay: (index + 1) * 0.2 + 0.2,
                }}
              >
                <RMSTestimonialCard testimonial={testimonial} />
              </motion.div>
            ))}
          </div>

          {blockElement.value.show_more_button_link && (
            <motion.div
              {...configOpacitySlideUp}
              className={styles.buttonContainer}
            >
              <Link
                to={blockElement.value.show_more_button_link ?? ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className={classNames('primary rms-clickable', styles.button)}
                >
                  Mehr anzeigen
                </button>
              </Link>
            </motion.div>
          )}
        </div>
      </div>
    </section>
  )
}
