import rememberCookieConsentGiven from '../features/rms-matomo/remember-cookie-consent-given'
import forgetCookieConsentGiven from '../features/rms-matomo/forget-cookie-consent-given'

function setMatomoCookieConsent(consent: any) {
  if (consent.cookie.categories.includes('analytics')) {
    rememberCookieConsentGiven()
  } else {
    forgetCookieConsentGiven()
  }
}

const cookieConsentConfig = {
  revision: 1,
  palette: {
    popup: {
      background: 'var(--rms-color-primary-500)',
      text: 'var(--rms-color-light)',
    },
    button: {
      background: 'var(--rms-color-dark)',
      text: 'var(--rms-color-light)',
    },
  },
  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
    },
    analytics: {
      enabled: false,
      readOnly: false,
    },
  },
  onChange: (consent: any) => {
    setMatomoCookieConsent(consent)
  },
  onConsent: (consent: any) => {
    setMatomoCookieConsent(consent)
  },
  language: {
    default: 'de',
    translations: {
      de: {
        consentModal: {
          title: 'Cookies',
          description: 'Dürfen wir Cookies verwenden?',
          acceptAllBtn: 'Alle akzeptieren',
          acceptNecessaryBtn: 'Nur notwendige Cookies',
          showPreferencesBtn: 'Individuell',
        },
        preferencesModal: {
          title: 'Individuell',
          acceptAllBtn: 'Alle akzeptieren',
          acceptNecessaryBtn: 'Nur notwendige Cookies',
          savePreferencesBtn: 'Auswahl akzeptieren',
          closeIconLabel: 'Close modal',
          sections: [
            {
              title: 'Cookies?',
              description: 'Hört sich gut an!',
            },
            {
              title: 'Notwendige Cookies',
              description:
                'Diese Cookies werden für den Betrieb der Website benötigt, und können nicht deaktiviert werden.',

              //this field will generate a toggle linked to the 'necessary' category
              linkedCategory: 'necessary',
            },
            {
              title: 'Analytische Cookies',
              description:
                'Diese Cookies helfen uns, unsere Website zu verbessern, indem sie Informationen über die Nutzung der Website sammeln. (Matomo)',

              //this field will generate a toggle linked to the 'necessary' category
              linkedCategory: 'analytics',
            },
            {
              title: 'Datenschutz',
              description:
                'Weitere Informationen finden Sie in unserer <a href="/datenschutz">Datenschutzerklärung</a>.',
            },
            {
              title: 'Weitere Fragen',
              description:
                'Für weitere Fragen, kontaktiere uns gerne: <a href="/employees/martin-rader">Kontaktiere uns</a>',
            },
          ],
        },
      },
    },
  },
}

export default cookieConsentConfig
