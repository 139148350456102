import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { cmsInitialState } from '../../reducers/rmsCmsReducer'
import { fetchPage } from '../../pages/actions/fetchPage'
import { LoadingState } from '../../../api/models/LoadingState'
import { getPageWithPath } from '../../pages/reducers/rmsCmdReducerLogic'
import { fetchMainMenu } from '../actions/fetchMainMenu'

export const rmsCmsMainMenuReducerLogic = (
  builder: ActionReducerMapBuilder<typeof cmsInitialState>,
) => {
  builder.addCase(fetchMainMenu.pending, (state, action) => {
    let mainMenu = state.mainMenu

    if (!mainMenu) {
      mainMenu = {
        loadingState: LoadingState.Loading,
      }
      state.mainMenu = mainMenu
    } else {
      mainMenu.loadingState = LoadingState.Loading
    }
  })
  builder.addCase(fetchMainMenu.fulfilled, (state, action) => {
    if (state.mainMenu) {
      state.mainMenu.payload = action.payload
      state.mainMenu.fetchedAtTimestamp = new Date().getTime()
      state.mainMenu.loadingState = LoadingState.Success
    }
  })
  builder.addCase(fetchMainMenu.rejected, (state, action) => {
    if (state.mainMenu) {
      state.mainMenu.fetchedAtTimestamp = new Date().getTime()
      state.mainMenu.loadingState = LoadingState.Success
      state.mainMenu.error = {
        message: action.error.message,
        statusCode: action.error.code,
        errorObject: action.error,
      }
    }
  })
}
