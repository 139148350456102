import React, { useEffect, useState } from 'react'
import logo from './logo.svg'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/plugins/counter.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import './App.css'
import './styles/plugins/cookie-consent-custom.css'
import TopHeader from './app/main/elements/header/TopHeader/TopHeader'
import RootPage from './app/main/elements/pages/RootPage/RootPage'
import {
  BrowserRouter,
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
} from 'react-router-dom'
import MainFooter from './app/main/elements/footer/MainFooter/MainFooter'
import { ParallaxProvider } from 'react-scroll-parallax'
import * as CookieConsent from 'vanilla-cookieconsent'
import cookieConsentConfig from './app/configs/cookie-consent-config'
import useMatomoTracking from './app/features/rms-matomo/use-matomo-tracking'
import useMatomoReactLinkTracking from './app/features/rms-matomo/use-matomo-react-link-tracking'
import Lightbox from 'yet-another-react-lightbox'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from './app/main/store'
import { setSelectedGroup } from './app/main/redux/slices/lightboxSlice'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Counter from 'yet-another-react-lightbox/plugins/counter'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import RMSDialog from './app/features/rms-react-dialogs/components/rms-dialog/RMSDialog'
import RMSDialogCard from './app/features/rms-react-dialogs/components/rms-dialog-card/RMSDialogCard'
import {
  removeDialog,
  setOpenStatus,
} from './app/main/redux/slices/dialogsSlice'
import DHRichTextElement from './app/main/elements/components/elements/dh-rich-text-element/DHRichTextElement'
import AOS from 'aos'

function AppLayout() {
  const dispatch = useDispatch()
  const selectedGroupParams = useSelector(
    (state: RootState) => state.lightbox.selectedGroup,
  )
  const selectedGroup = useSelector((state: RootState) => {
    return selectedGroupParams
      ? state.lightbox.slideGroups[selectedGroupParams.key]
      : undefined
  })
  const stateDialogs = useSelector((state: RootState) => state.dialogs.dialogs)
  const closeLightbox = () => {
    dispatch(setSelectedGroup(undefined))
  }

  useMatomoReactLinkTracking()
  return (
    <div className="App" data-testid="app">
      <TopHeader data-testid="top-header" />
      <Outlet />
      <MainFooter data-testid="main-footer" />
      <Lightbox
        open={selectedGroup !== undefined}
        close={() => closeLightbox()}
        index={selectedGroupParams?.index ?? 0}
        slides={selectedGroup?.slides}
        plugins={[Counter, Captions, Thumbnails, Fullscreen, Slideshow, Zoom]}
      />
      {stateDialogs &&
        stateDialogs.map(dialog => {
          return (
            <RMSDialog
              key={dialog.id}
              startClose={!dialog.open}
              onClickBackground={() => {
                dispatch(setOpenStatus({ id: dialog.id!, open: false }))
                setTimeout(() => {
                  dispatch(removeDialog(dialog.id!))
                }, 1000)
              }}
            >
              <RMSDialogCard
                title={dialog.title}
                startClose={!dialog.open}
                onClose={() => {
                  dispatch(setOpenStatus({ id: dialog.id!, open: false }))
                  setTimeout(() => {
                    dispatch(removeDialog(dialog.id!))
                  }, 1000)
                }}
                footer={
                  <button
                    className={'primary'}
                    onClick={() => {
                      dispatch(setOpenStatus({ id: dialog.id!, open: false }))
                      setTimeout(() => {
                        dispatch(removeDialog(dialog.id!))
                      }, 1000)
                    }}
                  >
                    {dialog.button_text ?? 'Gelesen'}
                  </button>
                }
              >
                <DHRichTextElement
                  dhRichText={{ rich_text: dialog.description ?? '' }}
                />
              </RMSDialogCard>
            </RMSDialog>
          )
        })}
    </div>
  )
}

function App() {
  /* Matomo Tracking */
  // ! Note: Uncomment the following lines to enable Matomo tracking
  /*useMatomoTracking({
    siteId: process.env.REACT_APP_MATOMO_SITE_ID ?? '1',
    trackerUrl:
      process.env.REACT_APP_MATOMO_TRACKER_URL ??
      'https://matomo.rm-softwares.at/',
    disableCookies: false,
    requireCookieConsent: true,
    disableTrackPageView: true,
  })*/

  /* Config for AOS */
  useEffect(() => {
    /* Init AOS */
    AOS.init({
      once: true,
      duration: 600,
      easing: 'ease-out-sine',
    })
  }, [])

  useEffect(() => {
    CookieConsent.run(cookieConsentConfig)
  }, [])

  const router = createBrowserRouter([
    {
      element: <AppLayout />,
      children: [
        {
          path: '/',
          element: <RootPage />,
        },
        {
          path: '/*',
          element: <RootPage />,
        },
      ],
    },
  ])

  return (
    <ParallaxProvider>
      <RouterProvider router={router} />
    </ParallaxProvider>
  )
}

export default App
