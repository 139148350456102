import React from 'react'
import styles from './RMSCard.module.scss'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

interface IProps {
  image: string
  title: string
  text: string
  buttonText: string
  to: string
}

export default function RMSCard(props: IProps): React.JSX.Element {
  return (
    <div className={styles.card}>
      <Link to={props.to} className="custom">
        <img className={styles.image} src={props.image} alt="Bildvorschau" />
      </Link>

      <div className={styles.content}>
        <Link to={props.to} className="custom">
          <h5 className={styles.title}>{props.title}</h5>
        </Link>
        <p className={styles.text}>{props.text}</p>
        <Link
          to={props.to}
          className={classNames('button primary', 'custom', styles.button)}
        >
          {props.buttonText}
          <svg
            className={styles.svg}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </Link>
      </div>
    </div>
  )
}
