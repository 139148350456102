import styles from './DefaultPageContainer.module.scss'
import WaveContainer from '../wave-container/WaveContainer'
import multipleClassName from '../../../../../utils/multipleClassName'
import SecondaryParallaxBanner from '../../banners/secondary-parallax-banner/SecondaryParallaxBanner'
import { motion } from 'framer-motion'
import { configOpacitySlideUp } from '../../../animations/framer-motion/motion-configs'
import classNames from 'classnames'
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax'
import rmsCmsImageUrl from '../../../../../features/cms/utils/rmsCmsImageUrl'

export default function DefaultPageContainer({
  children,
  title,
  titlePicture,
  className,
  backgroundClassName,
}: {
  children: React.ReactNode
  title?: string
  titlePicture?: string
  className?: string
  backgroundClassName?: string
}) {
  return (
    <>
      <div className={classNames(styles.container, className)}>
        <ParallaxBanner
          layers={[
            {
              image: rmsCmsImageUrl(titlePicture ?? ''),
              speed: 20,
            },
          ]}
          className={styles.banner}
        >
          <ParallaxBannerLayer className={styles.overlay} />
          <ParallaxBannerLayer className={styles.titleContainer}>
            <h1 className={styles.title} data-aos="fade-up">
              {title}
            </h1>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <div className={styles.content}>
          <div className={styles.contentInner}>{children}</div>
        </div>
      </div>
    </>
  )
}
