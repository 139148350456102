import styles from './RMSEmployeeSmall.module.scss'
import { motion } from 'framer-motion'
import rmsCmsImageUrl from '../../../../../cms/utils/rmsCmsImageUrl'
import { configOpacityScale } from '../../../../../../main/elements/animations/framer-motion/motion-configs'
import RMSTechnologiesContainer from '../../../technologies/components/rms-technologies-container/RMSTechnologiesContainer'
import { Link } from 'react-router-dom'
import { RMSEmployee } from '../../models/RMSEmployee'
import RMSEmployeeProfilePicture from '../rms-employee-profile-picture/RMSEmployeeProfilePicture'
import { useCallback, useEffect } from 'react'
import classNames from 'classnames'

export default function RMSEmployeeSmall({
  employee,
  alignImageLeft,
  hideHr,
}: {
  employee: RMSEmployee
  alignImageLeft: boolean
  hideHr?: boolean
}) {
  const getEmployeeFullName = useCallback(() => {
    return `${employee.pre_title ?? ''} ${employee.first_name} ${employee.last_name} ${employee.post_title ?? ''}`
  }, [
    employee.pre_title,
    employee.first_name,
    employee.last_name,
    employee.post_title,
  ])

  return (
    <motion.div {...configOpacityScale} className={styles.container}>
      <div className={styles.content}>
        <img
          className={classNames(styles.image, {
            [styles.left]: alignImageLeft,
            [styles.right]: !alignImageLeft,
          })}
          src={rmsCmsImageUrl(employee.profile_picture ?? '')}
          alt={`Profilbild ${employee.first_name} ${employee.last_name}`}
        />

        <div
          className={classNames(styles.text, {
            [styles.left]: !alignImageLeft,
            [styles.right]: alignImageLeft,
          })}
        >
          <h2 className={styles.name}>{getEmployeeFullName()}</h2>
          <p className={styles.position}>{employee.job_position}</p>
          <p className={styles.description}>{employee.short_description}</p>
        </div>
      </div>
      {hideHr !== true && <hr className={styles.hr} />}
    </motion.div>
  )
}
