import styles from './RMSBreadcrumb.module.scss'
import { BreadcrumbPart } from '../../../pages/RMSPage/RMSPageModel'
import { Link } from 'react-router-dom'

export default function RMSBreadcrumb({
  breadcrumb_parts,
}: {
  breadcrumb_parts: BreadcrumbPart[]
}) {
  return (
    <div className={styles.RMSBreadcrumb}>
      {breadcrumb_parts.map((part, index) => {
        return (
          <>
            <Link to={part.url} key={part.id}>
              {part.title}
            </Link>
            {index < breadcrumb_parts.length - 1 && <span> {'>'} </span>}
          </>
        )
      })}
    </div>
  )
}
