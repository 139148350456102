import styles from './RMSChoosedImageElement.module.scss'
import RMSChoosedImageElementModel, {
  RMSChoosedImageRendition,
} from './RMSChoosedImageElementModel'
import rmsCmsImageUrl from '../../../../../features/cms/utils/rmsCmsImageUrl'
import getNearestRendition from './utils/getNearestRendition'
import { CSSProperties, useEffect, useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import { useDispatch } from 'react-redux'
import {
  addToSlideGroup,
  setSelectedGroup,
} from '../../../../redux/slices/lightboxSlice'
import multipleClassName from '../../../../../utils/multipleClassName'
import classNames from 'classnames'

export default function RMSChoosedImageElement({
  choosedImage,
  preferredWidth,
  preferredHeight,
  className,
  style,
  disableClick,
}: {
  choosedImage: RMSChoosedImageElementModel
  preferredWidth?: number
  preferredHeight?: number
  className?: string
  style?: CSSProperties
  disableClick?: boolean
}) {
  const dispatch = useDispatch()

  const imagePath = () => {
    const renditions = choosedImage.renditions

    if (renditions && renditions.length > 0) {
      if (preferredWidth) {
        return (
          getNearestRendition(
            renditions,
            preferredWidth,
            rendition => rendition.width,
          )?.image ?? choosedImage.image
        )
      }
      if (preferredHeight) {
        return (
          getNearestRendition(
            renditions,
            preferredHeight,
            rendition => rendition.height,
          )?.image ?? choosedImage.image
        )
      }
      if (choosedImage.preferred_render_width) {
        return (
          getNearestRendition(
            renditions,
            choosedImage.preferred_render_width,
            rendition => rendition.width,
          )?.image ?? choosedImage.image
        )
      }
      if (choosedImage.preferred_render_height) {
        return (
          getNearestRendition(
            renditions,
            choosedImage.preferred_render_height,
            rendition => rendition.height,
          )?.image ?? choosedImage.image
        )
      }
      return renditions[0].image
    }

    return choosedImage.image
  }

  const calculatedImagePath = imagePath()

  const convertObjectFit = (value: string | undefined) => {
    // Define a mapping of possible values
    const validValues = ['contain', 'cover', 'fill', 'scale-down', 'none']

    if (value) {
      // Convert the input value to lowercase for case-insensitive matching
      const lowercaseValue = value.toLowerCase()

      // Check if the input value is in the validValues array
      if (validValues.includes(lowercaseValue)) {
        return lowercaseValue // Return the valid value
      }
    } else {
      return 'cover' // Default to "cover" if the input is not valid
    }
  }

  const containerBackground = () => {
    if (!calculatedImagePath) {
      return choosedImage.background
    } else if (choosedImage.show_background_behind_image) {
      return choosedImage.background
    }
    return 'transparent'
  }

  const imgSrc = calculatedImagePath ? rmsCmsImageUrl(calculatedImagePath) : ''
  useEffect(() => {
    dispatch(
      addToSlideGroup({
        key: 'page',
        slideImage: {
          src: imgSrc,
          alt: choosedImage.alt_text ?? '',
          description: choosedImage.alt_text ?? '',
        },
      }),
    )
  }, [choosedImage])

  return (
    <div
      className={styles.RichTextElementContainer}
      style={{
        padding: choosedImage.container_padding || '0',
        background: containerBackground(),
        width: choosedImage.container_width || '100%',
        height: choosedImage.container_height || '100%',
      }}
    >
      <img
        onClick={() => {
          if (disableClick !== true) {
            dispatch(
              setSelectedGroup({
                key: 'page',
                img: imgSrc,
              }),
            )
          }
        }}
        src={imgSrc}
        alt={choosedImage.alt_text}
        className={classNames(className, {
          'rms-clickable': disableClick !== true,
          'rms-hover-move-up': disableClick !== true,
        })}
        style={{
          width: choosedImage.width || '100%',
          height: choosedImage.height || '100%',
          objectFit: convertObjectFit(choosedImage.object_fit) as any,
          objectPosition: choosedImage.object_position || 'center center',
          ...style,
        }}
      />
    </div>
  )
}
