import { RMSEmployeesBlockModel } from './RMSEmployeesBlockModel'
import styles from './RMSEmployeesBlock.module.scss'
import multipleClassName from '../../../../utils/multipleClassName'
import transformToReactStyle, {
  transformToReactMaxWidthContainerStyle,
} from '../../components/elements/rms-css-style-block/transformToReactStyle'
import transformToReactContainerClasses from '../../components/elements/rms-css-style-block/transformToReactClasses'
import { motion } from 'framer-motion'
import {
  configOpacityScale,
  configOpacitySlideUp,
} from '../../animations/framer-motion/motion-configs'
import RMSEmployeeSmall from '../../../../features/rms-projects/features/employee/components/rms-employee-small/RMSEmployeeSmall'
import classNames from 'classnames'

export default function RMSEmployeesBlock({
  blockElement,
}: {
  blockElement: RMSEmployeesBlockModel
}) {
  return (
    <section
      className={classNames(
        styles.section,
        blockElement.value.css_style?.additional_classes,
      )}
      style={transformToReactStyle(blockElement.value.css_style)}
    >
      <div className={styles.content}>
        <div className={classNames(styles.inner)}>
          {blockElement.value.title && (
            <motion.h2 {...configOpacitySlideUp} className={styles.title}>
              {blockElement.value.title}
            </motion.h2>
          )}

          {blockElement.value.subtitle && (
            <motion.p
              {...configOpacitySlideUp}
              transition={{
                ...configOpacitySlideUp.transition,
                delay: 0.2,
              }}
              className={styles.subtitle}
            >
              {blockElement.value.subtitle}
            </motion.p>
          )}

          <div className={classNames(styles.team)}>
            {blockElement.value.employees &&
              blockElement.value.employees.map((employee, index) => {
                return (
                  <RMSEmployeeSmall
                    key={index}
                    employee={employee}
                    alignImageLeft={index % 2 == 0}
                    hideHr={
                      index == (blockElement.value.employees?.length ?? 0) - 1
                    }
                  />
                )
              })}
          </div>
        </div>
      </div>
    </section>
  )
}
