import { createReducer } from '@reduxjs/toolkit'
import { RMSApiContainer } from '../../api/models/RMSApiContainer'
import StatusDialog from '../models/StatusDialog'
import { statusDialogReducerLogic } from './statusDialogReducerLogic'

export const rmsStatusDialogInitialState: {
  statusDialogs?: RMSApiContainer<StatusDialog[]>
} = {
  statusDialogs: undefined,
}

export const rmsStatusDialogReducer = createReducer(
  rmsStatusDialogInitialState,
  builder => {
    statusDialogReducerLogic(builder)
  },
)
