import styles from './FeaturePresenterBlock.module.scss'
import multipleClassName from '../../../../utils/multipleClassName'
import { FeaturePresenterBlockModel } from './FeaturePresenterBlockModel'
import rmsCmsImageUrl from '../../../../features/cms/utils/rmsCmsImageUrl'
import RMSImageMultiSizeElement from '../../components/elements/rms-image-multi-size-element/RMSImageMultiSizeElement'
import rmsMultiSizeImgValid from '../../components/elements/rms-image-multi-size-element/utils/rmsMultiSizeImgValid'

export default function FeaturePresenterBlock({
  blockElement,
}: {
  blockElement: FeaturePresenterBlockModel
}) {
  const blockValue = blockElement.value

  return (
    <div
      className={multipleClassName(
        styles.FeaturePresenterBlock,
        'IgnoreMaxWidth',
        blockValue.color_style === 'dark' ? styles.darkStyle : '',
      )}
    >
      {rmsMultiSizeImgValid(blockValue.background_image) && (
        <div className={styles.backgroundImageContainer}>
          <RMSImageMultiSizeElement
            imageMultiSize={blockValue.background_image!}
          />
        </div>
      )}
      <div
        className={multipleClassName(
          styles.inner,
          blockValue.layout_direction === 'right' ? styles.rightToLeft : '',
        )}
        style={{ marginTop: blockValue.inner_margin_top ?? '0' }}
      >
        {rmsMultiSizeImgValid(blockValue.image) && (
          <RMSImageMultiSizeElement
            imageMultiSize={blockValue.image!}
            className={styles.FeaturePresenterBlock__Screenshot}
          />
        )}
        <div className={styles.textBlock}>
          <h2>{blockValue.title}</h2>
          {blockValue.subtitle && <h3>{blockValue.subtitle}</h3>}
          {blockValue.feature_content && (
            <div
              className={styles.featureContent}
              dangerouslySetInnerHTML={{ __html: blockValue.feature_content }}
            />
          )}
          {blockValue.show_more_text && blockValue.show_more_link && (
            <div>
              <a
                href={blockValue.show_more_link}
                className={multipleClassName(styles.showMoreLink)}
              >
                <button
                  className={multipleClassName(
                    blockValue.color_style === 'dark'
                      ? 'secondary light'
                      : 'primary',
                    'rms-hover-scale',
                  )}
                >
                  {blockValue.show_more_text}
                </button>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
