import { Link, useLocation } from 'react-router-dom'
import styles from './RMSPagination.module.scss'
import multipleClassName from '../../../../../utils/multipleClassName'

export default function RMSPagination({
  totalAmount,
  pageSize,
}: {
  totalAmount: number
  pageSize?: number
}) {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  pageSize = parseInt(
    queryParams.get('page_size') ?? pageSize?.toString() ?? '10',
  )
  const page = parseInt(queryParams.get('page') ?? '1')

  const pages = Math.ceil(totalAmount / pageSize)
  const currentPage = page
  const pageNumbers = []
  for (let i = 1; i <= pages; i++) {
    pageNumbers.push(i)
  }
  return (
    <div className={styles.RMSPagination}>
      {currentPage > 1 && (
        <Link to={`?page=${currentPage - 1}&page_size=${pageSize}`}>
          <a className={multipleClassName('rms-clickable')}>
            <strong>«</strong>
          </a>
        </Link>
      )}
      {pageNumbers.map(number => (
        <Link to={`?page=${number}&page_size=${pageSize}`}>
          <a
            className={multipleClassName(
              number === currentPage ? styles.Active : undefined,
              'rms-clickable',
            )}
            key={number}
          >
            {number}
          </a>
        </Link>
      ))}
      {currentPage < pages && (
        <Link to={`?page=${currentPage + 1}&page_size=${pageSize}`}>
          <a className={multipleClassName('rms-clickable')}>
            <strong>»</strong>
          </a>
        </Link>
      )}
    </div>
  )
}
