import { Link, useMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useRMSApiConfig } from '../../../../features/api/contexts/RMSApiContext'
import { useEffect, useState } from 'react'
import { fetchMainMenu } from '../../../../features/cms/menus/actions/fetchMainMenu'
import { useMainMenuWithPage } from '../../../../features/cms/hooks/useMainMenuSelector'
import multipleClassName from '../../../../utils/multipleClassName'
import spitzerLogo from '../../../../../assets/images/spitzer_logo.jpg'
import mountainBottomOrange from '../../../../../assets/images/mountainBottomOrange.png'
import styles from './TopHeader.module.scss'
import classNames from 'classnames'

export default function TopHeader() {
  const dispatch = useDispatch()
  const apiConfig = useRMSApiConfig()
  const {
    mainMenuItems,
    loadingState: pageLoadingState,
    error: pageError,
  } = useMainMenuWithPage()

  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    dispatch(fetchMainMenu({ apiConfig: apiConfig }) as any)
  }, [dispatch])

  function MenuItem({ name = 'Menu Item', menuLink = '/' }) {
    const match = useMatch(menuLink ?? '')

    return (
      <li>
        <Link
          to={menuLink}
          onClick={() => setShowMenu(false)}
          className={
            match !== null ? styles.selectedElement : styles.defaultElement
          }
        >
          {name}
        </Link>
      </li>
    )
  }

  const renderMenu =
    mainMenuItems &&
    mainMenuItems.map((item, index) => {
      return <MenuItem key={index} name={item.menu_text} menuLink={item.path} />
    })

  return (
    <>
      <header className={styles.header}>
        <nav className={styles.nav}>
          <div className={styles.container}>
            <Link to="/" className={classNames(styles.logoContainer, 'custom')}>
              <img
                src={spitzerLogo}
                className={styles.logo}
                alt="Ordination Logo"
              />
              <span className={styles.text}>Zahnarzt Dr. Spitzer</span>
            </Link>

            <div className={styles.dropdownContainer}>
              <button
                onClick={() => setShowMenu(!showMenu)}
                type="button"
                className={styles.dropdownButton}
                aria-controls="mobile-menu-2"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={classNames(styles.dropdownIcon, {
                    hidden: showMenu === true,
                  })}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  className={classNames(styles.dropdownIcon, {
                    hidden: showMenu !== true,
                  })}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>

            <div
              className={classNames(styles.menu, { hidden: showMenu !== true })}
              id="mobile-menu-2"
            >
              <ul className={styles.list}>{renderMenu}</ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}
