import { ServicePageModel } from './ServicePageModel'
import DHContentBlock from '../../blocks/dh-content-block/DHContentBlock'
import useBackgroundColor from '../../../hooks/style/useBackgroundColor'
import DefaultPageContainer from '../../components/containers/default-page-container/DefaultPageContainer'
import styles from '../ServicePage/ServicePage.module.scss'
import LeftRightFloatingContainer from '../../components/containers/left-right-floating-container/LeftRightFloatingContainer'
import rmsCmsImageUrl from '../../../../features/cms/utils/rmsCmsImageUrl'
import { Link } from 'react-router-dom'
import multipleClassName from '../../../../utils/multipleClassName'
import { useEffect } from 'react'
import DHRichTextBlock from '../../blocks/dh-richtext-block/DHRichTextBlock'
import DHRichTextElement from '../../components/elements/dh-rich-text-element/DHRichTextElement'
import RMSBadge from '../../components/elements/rms-badge/RMSBadge'
import {
  configOpacitySlideLeft,
  configOpacitySlideUp,
} from '../../animations/framer-motion/motion-configs'
import { motion } from 'framer-motion'

export default function ServicePage({ page }: { page: ServicePageModel }) {
  return (
    <DefaultPageContainer
      title={page.service.name}
      className={styles.ServicePage}
      titlePicture={page.service.title_picture}
    >
      {page.service.content && <DHContentBlock blocks={page.service.content} />}

      {page.service.related_technologies &&
        page.service.related_technologies.length > 0 && (
          <div className={styles.relatedTechnologies}>
            <motion.div {...configOpacitySlideUp} className={styles.title}>
              <h3>Erwähnte Technologien</h3>
            </motion.div>
            <motion.div
              {...configOpacitySlideUp}
              transition={{
                ...configOpacitySlideUp.transition,
                delay: 0.2,
              }}
              className={styles.badges}
            >
              {page.service.related_technologies.map((tech, index) => (
                <RMSBadge
                  key={index}
                  to={'/technologies/' + tech.technology.slug}
                >
                  {tech.technology.name}
                </RMSBadge>
              ))}
            </motion.div>
          </div>
        )}
    </DefaultPageContainer>
  )
}
