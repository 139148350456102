import { RMSPageModel } from './RMSPageModel'
import DHContentBlock from '../../blocks/dh-content-block/DHContentBlock'
import styles from './RMSPage.module.scss'
import multipleClassName from '../../../../utils/multipleClassName'
import useBackgroundColor from '../../../hooks/style/useBackgroundColor'
import DefaultPageContainer from '../../components/containers/default-page-container/DefaultPageContainer'
import RMSDialogCard from '../../../../features/rms-react-dialogs/components/rms-dialog-card/RMSDialogCard'
import RMSDialog from '../../../../features/rms-react-dialogs/components/rms-dialog/RMSDialog'
import React, { useEffect, useState } from 'react'
import RMSPagePreviewElement from '../../components/elements/rms-page-preview-element/RMSPagePreviewElement'
import RMSPagination from '../../components/elements/rms-pagination/RMSPagination'
import RMSBreadcrumb from '../../components/elements/rms-breadcrumb/RMSBreadcrumb'
import RMSPageMetadata from '../../components/elements/rms-page-metadata/RMSPageMetadata'

export default function RMSPage({ page }: { page: RMSPageModel }) {
  let backgroundColor = page.background_color

  const getPageStyle = () => {
    switch (page.page_styles) {
      case 'only_text':
        return styles.onlyText
      default:
        return ''
    }
  }

  let content = (
    <div className={multipleClassName(styles.BlogPage, getPageStyle())}>
      {page.show_title && (
        <div className={styles.BlogPage__Header}>
          <h1>{page.title}</h1>
        </div>
      )}
      <DHContentBlock blocks={page.content} />
    </div>
  )

  if (page.page_styles === 'default' || page.page_styles === 'index_page') {
    if (!page.background_color) {
      backgroundColor = 'white'
    }
    content = (
      <DefaultPageContainer
        title={page.show_title ? page.title : undefined}
        titlePicture={page.title_picture ?? undefined}
      >
        <RMSPageMetadata page={page} />
        <DHContentBlock blocks={page.content} />
        {page.page_styles === 'index_page' &&
          page.children &&
          page.children.length > 0 && (
            <>
              <div className={styles.IndexContainer}>
                {page.children.map((child, index) => {
                  return <RMSPagePreviewElement pagePreview={child} />
                })}
              </div>
              <RMSPagination
                totalAmount={page.children_count ?? 1}
                pageSize={page.page_size}
              />
            </>
          )}
      </DefaultPageContainer>
    )
  }

  useBackgroundColor(backgroundColor)

  return content
}
