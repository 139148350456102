import React from 'react'
import RMSApiConfig, { defaultRMSApiConfig } from '../models/RMSApiConfig'

export interface RMSApiContextProps {
  config: RMSApiConfig
}

const RMSApiContext = React.createContext<RMSApiContextProps>({
  config: {
    ...defaultRMSApiConfig,
    headers: {
      'Content-Type': 'application/json',
    },
  },
})

export const useRMSApiContextProps: () => RMSApiContextProps = () =>
  React.useContext(RMSApiContext)
export const useRMSApiConfig: () => RMSApiConfig = () =>
  useRMSApiContextProps().config

export default RMSApiContext
