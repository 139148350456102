export default function rmsAlignmentClass(width: string | undefined): string {
  switch (width) {
    case 'left':
      return 'alignLeft'
    case 'center':
      return 'alignCenter'
    case 'right':
      return 'alignRight'
    default:
      return ''
  }
}
