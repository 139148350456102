import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit'
import { fetchPage } from '../actions/fetchPage'
import { RMSInternalCMSPage } from '../models/RMSInternalCMSPage'
import { cmsInitialState } from '../../reducers/rmsCmsReducer'
import { LoadingState } from '../../../api/models/LoadingState'

export const getPageWithPath = (pages: RMSInternalCMSPage[], path: string) => {
  return pages.find(page => page.urlRequestPart === path)
}

export const rmsCmsPageReducerLogic = (
  builder: ActionReducerMapBuilder<typeof cmsInitialState>,
) => {
  builder.addCase(fetchPage.pending, (state, action) => {
    const path = action.meta.arg.pageId
    let internalPage = getPageWithPath(state.pages, path)

    if (!internalPage) {
      internalPage = {
        urlRequestPart: path,
        loadingState: LoadingState.Loading,
      }
      state.pages.push(internalPage)
    } else {
      internalPage.payload = action.payload
      internalPage.loadingState = LoadingState.Loading
    }
  })
  builder.addCase(fetchPage.fulfilled, (state, action) => {
    const path = action.meta.arg.pageId
    let internalPage = getPageWithPath(state.pages, path)
    if (internalPage) {
      internalPage.payload = action.payload
      internalPage.fetchedAtTimestamp = new Date().getTime()
      internalPage.loadingState = LoadingState.Success
    }
  })
  builder.addCase(fetchPage.rejected, (state, action) => {
    const path = action.meta.arg.pageId
    let internalPage = getPageWithPath(state.pages, path)

    if (internalPage) {
      internalPage.fetchedAtTimestamp = new Date().getTime()
      internalPage.loadingState = LoadingState.Error
      internalPage.error = {
        message: action.error.message,
        statusCode: action.error.code,
        errorObject: action.error,
      }
    }
  })
}
