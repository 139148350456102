import styles from './RMSGalleryBlock.module.scss'
import multipleClassName from '../../../../utils/multipleClassName'
import { RMSGalleryModel } from './RMSGalleryModel'
import rmsCmsImageUrl from '../../../../features/cms/utils/rmsCmsImageUrl'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import {
  addToSlideGroup,
  setSelectedGroup,
} from '../../../redux/slices/lightboxSlice'
import { configOpacitySlideUp } from '../../animations/framer-motion/motion-configs'
import { motion } from 'framer-motion'

export default function RMSGalleryBlock({
  blockElement,
}: {
  blockElement: RMSGalleryModel
}) {
  const dispatch = useDispatch()
  const key = blockElement.value.lightbox_group ?? 'page'

  useEffect(() => {
    // add the lightbox group to the store
    blockElement.value.elements?.forEach((element, index) => {
      dispatch(
        addToSlideGroup({
          key: key,
          slideImage: {
            src: rmsCmsImageUrl(element.image ?? ''),
            alt: element.alt_text ?? '',
            description: element.alt_text ?? '',
          },
        }),
      )
    })
  }, [blockElement.value])

  return (
    <div className={multipleClassName(styles.RMSGalleryBlock)}>
      <div className={styles.Inner}>
        {blockElement.value.elements?.map((element, index) => {
          return (
            <motion.div
              {...configOpacitySlideUp}
              transition={{
                ...configOpacitySlideUp.transition,
                delay: (index + 1) * 0.2 + 0.2,
              }}
              className={styles.ImgContainer}
            >
              <img
                src={rmsCmsImageUrl(element.thumbnail ?? '')}
                alt={element.alt_text}
                key={index}
                className={multipleClassName('rms-clickable rms-hover-move-up')}
                onClick={() =>
                  dispatch(
                    setSelectedGroup({
                      key: key,
                      img: rmsCmsImageUrl(element.image ?? ''),
                    }),
                  )
                }
              />
            </motion.div>
          )
        })}
      </div>
    </div>
  )
}
