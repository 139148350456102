import { createReducer } from '@reduxjs/toolkit'
import { RMSInternalCMSPage } from '../pages/models/RMSInternalCMSPage'
import { rmsCmsPageReducerLogic } from '../pages/reducers/rmsCmdReducerLogic'
import RMSCMSMainMenuElement from '../menus/models/RMSCMSMainMenu'
import { RMSApiContainer } from '../../api/models/RMSApiContainer'
import { rmsCmsMainMenuReducerLogic } from '../menus/reducers/rmsCmsMainMenuReducerLogic'
import { rmsCmsFlatMenuReducerLogic } from '../menus/reducers/rmsCmsFlatMenuReducerLogic'
import RMSCMSFlatMenu from '../menus/models/RMSCMSFlatMenu'

export const cmsInitialState: {
  pages: RMSInternalCMSPage[]
  mainMenu?: RMSApiContainer<RMSCMSMainMenuElement[]>
  footerMenu?: RMSApiContainer<RMSCMSFlatMenu>
} = {
  pages: [],
}

export const rmsCmsReducer = createReducer(cmsInitialState, builder => {
  rmsCmsPageReducerLogic(builder)
  rmsCmsMainMenuReducerLogic(builder)
  rmsCmsFlatMenuReducerLogic(builder)
})
