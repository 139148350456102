import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './app/main/store'
import 'bootstrap-icons/font/bootstrap-icons.css'
import RMSApiContext, {
  RMSApiContextProps,
} from './app/features/api/contexts/RMSApiContext'
import { defaultRMSApiConfig } from './app/features/api/models/RMSApiConfig'
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material'
import { deDE } from '@mui/material/locale'

/* Inter font */
import '@fontsource/inter' // Defaults to weight 400
import '@fontsource/inter/100.css' // Specify weight
import '@fontsource/inter/200.css' // Specify weight
import '@fontsource/inter/300.css' // Specify weight
import '@fontsource/inter/400.css' // Specify weight
import '@fontsource/inter/500.css' // Specify weight
import '@fontsource/inter/600.css' // Specify weight
import '@fontsource/inter/700.css' // Specify weight
import '@fontsource/inter/800.css' // Specify weight
import '@fontsource/inter/900.css' // Specify weight

const rmsApiContextProps: RMSApiContextProps = {
  config: {
    ...defaultRMSApiConfig,
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  },
}

/* Custom MUI theme for MUI components */
const theme = createTheme(
  {
    palette: {
      mode: 'light',
      // TODO: define colors somewhere in JS only once and import them here (like Famulenz project)
      primary: {
        main: '#25a385',
        light: '#7dd8bc',
        dark: '#136959',
      },
      secondary: {
        main: '#25a385',
        light: '#7dd8bc',
        dark: '#136959',
      },
    },
  },
  deDE,
)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <RMSApiContext.Provider value={rmsApiContextProps}>
          <App />
        </RMSApiContext.Provider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
