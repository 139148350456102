import React, { useCallback } from 'react'
import { RMSPartner } from '../models/RMSPartner'
import styles from './RMSPartnerCard.module.scss'
import rmsCmsImageUrl from '../../../../cms/utils/rmsCmsImageUrl'
import { Link } from 'react-router-dom'

interface IProps {
  partner: RMSPartner
}

export default function RMSPartnerCard(props: IProps): React.JSX.Element {
  const ImageContainer = useCallback(
    ({ children }: { children: any }): React.JSX.Element => {
      if (props.partner.url) {
        return (
          <Link
            to={props.partner.url ?? '#'}
            target={props.partner.url ? '_blank' : ''}
            className={styles.imageContainer}
          >
            {children}
          </Link>
        )
      } else {
        return <div className={styles.imageContainer}>{children}</div>
      }
    },
    [props.partner.url],
  )

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <ImageContainer>
          <img
            className={styles.image}
            src={rmsCmsImageUrl(props.partner.image ?? '')}
            alt={`${props.partner.title} Logo`}
          />
        </ImageContainer>

        {props.partner.title && (
          <h4 className={styles.title}>{props.partner.title}</h4>
        )}

        {props.partner.subtitle && (
          <p className={styles.subtitle}>{props.partner.subtitle}</p>
        )}

        {props.partner.url && (
          <div className={styles.linkContainer}>
            <Link
              to={props.partner.url}
              target="_blank"
              className={styles.link}
            >
              Zur Webseite{' '}
              <svg
                className={styles.svg}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
