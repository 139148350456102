import { TechnologyPageModel } from './TechnologyPageModel'
import DHContentBlock from '../../blocks/dh-content-block/DHContentBlock'
import useBackgroundColor from '../../../hooks/style/useBackgroundColor'
import DefaultPageContainer from '../../components/containers/default-page-container/DefaultPageContainer'
import styles from '../TechnologyPage/TechnologyPage.module.scss'
import LeftRightFloatingContainer from '../../components/containers/left-right-floating-container/LeftRightFloatingContainer'
import rmsCmsImageUrl from '../../../../features/cms/utils/rmsCmsImageUrl'
import { Link } from 'react-router-dom'
import multipleClassName from '../../../../utils/multipleClassName'
import { useEffect } from 'react'
import DHRichTextElement from '../../components/elements/dh-rich-text-element/DHRichTextElement'

export default function TechnologyPage({
  page,
}: {
  page: TechnologyPageModel
}) {
  return (
    <DefaultPageContainer
      title={page.technology.name}
      titlePicture={page.technology.title_picture}
      className={styles.TechnologyPage}
    >
      {page.technology.description && (
        <DHRichTextElement
          dhRichText={{ rich_text: page.technology.description }}
        />
      )}

      {page.technology.content && (
        <DHContentBlock blocks={page.technology.content} />
      )}
    </DefaultPageContainer>
  )
}
