import { useEffect } from 'react'
import { RMSCMSPageMeta } from '../features/cms/pages/models/RMSCMSPage'

function useSetTitle(title: string) {
  useEffect(() => {
    document.title = `Dr. Spitzer - ${title}`
  }, [title])
}

export function setDHTitle(title?: string) {
  const fallbackTitle = 'Dr. Gernot Spitzer | Zahnarzt Gleisdorf'

  const ogSiteName = document.querySelector('meta[property="og:site_name"]')

  if (title) {
    document.title = title
    ogSiteName?.setAttribute('content', title)
  } else {
    document.title = fallbackTitle
    ogSiteName?.setAttribute('content', fallbackTitle)
  }
}

export function setSEOMetaDescription(description?: string) {
  const meta = document.querySelector('meta[name="description"]')
  const ogDescription = document.querySelector(
    'meta[property="og:description"]',
  )

  if (description) {
    meta?.setAttribute('content', description)
    ogDescription?.setAttribute('content', description)
  }
}

export function setSEOTags(meta?: RMSCMSPageMeta) {
  if (meta) {
    setDHTitle(meta.seo_title)
    setSEOMetaDescription(meta.search_description)
  }

  // Page URL
  let pageURL = meta?.html_url || undefined
  if (pageURL?.endsWith('/home/')) {
    pageURL = pageURL.replace(/\/home\/$/, '/')
  }

  // Set og:url
  const ogUrl = document.querySelector('meta[property="og:url"]')
  if (pageURL) {
    ogUrl?.setAttribute('content', pageURL)
  }

  // Set canonical URL
  if (pageURL) {
    let linkCanonical = document.querySelector(
      'link[rel="canonical"]',
    ) as HTMLLinkElement
    if (!linkCanonical) {
      linkCanonical = document.createElement('link') as HTMLLinkElement
      linkCanonical.rel = 'canonical'
      document.head.insertBefore(linkCanonical, document.head.firstChild)
    }
    linkCanonical.href = pageURL
  }
}

export default useSetTitle
