import { RMSTechnologiesBlockModel } from './RMSTechnologiesBlockModel'
import styles from './RMSTechnologiesBlock.module.scss'
import multipleClassName from '../../../../utils/multipleClassName'
import transformToReactStyle, {
  transformToReactMaxWidthContainerStyle,
} from '../../components/elements/rms-css-style-block/transformToReactStyle'
import transformToReactContainerClasses from '../../components/elements/rms-css-style-block/transformToReactClasses'
import { motion } from 'framer-motion'
import { configOpacityScale } from '../../animations/framer-motion/motion-configs'
import RMSTechnologyShort from '../../../../features/rms-projects/features/technologies/components/rms-technology-short/RMSTechnologyShort'

export default function RMTechnologiesBlock({
  blockElement,
}: {
  blockElement: RMSTechnologiesBlockModel
}) {
  const data = blockElement.value

  return (
    <div
      className={multipleClassName(
        styles.RMSTechnologiesBlock,
        'IgnoreMaxWidth',
        'MaxWidthOuterContainer',
      )}
      style={transformToReactStyle(blockElement.value.css_style)}
    >
      <div
        className={multipleClassName(
          styles.inner,
          'MaxWidthContainer',
          transformToReactContainerClasses(blockElement.value.css_style),
        )}
        style={transformToReactMaxWidthContainerStyle(
          blockElement.value.css_style,
        )}
      >
        <motion.div {...configOpacityScale} className={styles.header}>
          {data.title && <h2>{data.title}</h2>}
          {data.subtitle && <h3>{data.subtitle}</h3>}
        </motion.div>
        <div className={styles.content}>
          {data.technologies &&
            data.technologies.map((technology, index) => {
              return <RMSTechnologyShort key={index} technology={technology} />
            })}
        </div>
      </div>
    </div>
  )
}
