import React from 'react'
import styles from './RMSTestimonialCard.module.scss'
import { RMSTestimonial } from '../models/RMSTestimonial'

import parse from 'html-react-parser'
import classNames from 'classnames'

interface IProps {
  testimonial: RMSTestimonial
}

export default function RMSTestimonialCard(props: IProps): React.JSX.Element {
  const getRatingStar = (checked: boolean = true) => {
    return (
      <svg
        className={classNames(styles.ratingStar, {
          [styles.checked]: checked,
          [styles.unchecked]: !checked,
        })}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 22 20"
      >
        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
      </svg>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.rating}>
          <div className={styles.ratingStars}>
            {Array.from({ length: 5 }).map((_, i) => {
              return getRatingStar(true)
            })}
          </div>
          <span className={styles.ratingText}>5 Sterne</span>
        </div>

        {/* <h4 className={styles.title}>name</h4> */}

        <p className={styles.text}>{parse(props.testimonial.review)}</p>
      </div>
    </div>
  )
}
