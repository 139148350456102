import styles from './RMSDialogCard.module.scss'
import { ReactNode } from 'react'
import multipleClassName from '../../../../utils/multipleClassName'

type RMSDialogProps = {
  children: ReactNode
  title?: string
  footer?: ReactNode
  titleNode?: ReactNode
  startClose?: boolean
  onClose?: () => void
}

export default function RMSDialogCard({
  children,
  title,
  titleNode,
  footer,
  startClose,
  onClose,
}: RMSDialogProps) {
  // TODO: add logic to close the dialog (do it with a timer, to also support an animation)
  // TODO: don't implment the logic here, because it also affects the RMSDialog, and custom dialogs

  return (
    <div
      className={multipleClassName(
        styles.RMSDialogCard,
        startClose ? styles.close : '',
      )}
    >
      {(title || titleNode) && (
        <div className={styles.header}>
          {title && <h2 className={styles.title}>{title}</h2>}
          {titleNode}
          <button
            type="button"
            className={styles.closeButton}
            data-modal-hide="default-modal"
            onClick={onClose}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Schließen</span>
          </button>
        </div>
      )}

      <div className={styles.content}>{children}</div>

      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  )
}
