import React, { ReactElement, ReactNode } from 'react'

// Defining a constant array of HTML tags that we want to create components for
export const TagArray = [
  'div',
  'span',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'a',
  'button',
  'input',
  'label',
  'select',
  'textarea',
  'img',
  'ul',
  'ol',
  'li',
  'table',
  'tr',
  'td',
  'th',
  'thead',
  'tbody',
  'tfoot',
  'form',
  'section',
  'article',
  'aside',
  'nav',
  'header',
  'footer',
  'main',
  'figure',
  'figcaption',
  'blockquote',
  'cite',
  'code',
  'pre',
  'small',
  'strong',
  'em',
  'i',
  'b',
  'u',
  's',
  'sub',
  'sup',
  'abbr',
  'address',
  'time',
  'del',
  'ins',
  'dfn',
  'kbd',
  'mark',
  'q',
  'samp',
  'var',
  'meter',
  'progress',
  'output',
  'ruby',
  'rt',
  'rp',
  'bdi',
  'bdo',
  'wbr',
  'br',
  'hr',
  'area',
  'map',
  'embed',
  'object',
  'param',
  'video',
  'audio',
  'source',
  'track',
  'canvas',
  'script',
  'noscript',
  'caption',
  'col',
  'colgroup',
  'iframe',
  'details',
  'summary',
] as const

// Creating a type from the TagArray. This type will be used to ensure that only valid HTML tags are used
export type Tag = (typeof TagArray)[number]

// Default function to create a React Functional Component for a given HTML tag
// Use your own function to add animations like with framermotion
const createRMSTransformedComponent = (tag: Tag): React.FC<any> => {
  return ({ children, ...props }: any): ReactElement => {
    // The tag is used to create a React component
    const Component: Tag = tag
    // The component is returned with the passed props
    return <Component {...props}>{children}</Component>
  }
}

// Function to create an object where each key is a HTML tag and the value is a React Functional Component for that tag
export function createRMSComponentTransformerObject(
  createComponent: (tag: Tag) => React.FC<any>,
) {
  // Using the reduce function to create the object from the TagArray
  return TagArray.reduce(
    (acc, tag) => {
      // For each tag in the TagArray, a new property is added to the object
      // The key is the tag and the value is the result of the createComponent function
      acc[tag] = createComponent(tag)
      // The accumulator is returned to be used in the next iteration
      return acc
    },
    {} as Record<Tag, React.FC<any>>,
  ) // The initial value of the accumulator is an empty object
}

// Creating the rmsMotion object by calling the createRMSMotionObject function with the createRMSMotionComponent function as argument
export const defaultTransformerObject = createRMSComponentTransformerObject(
  createRMSTransformedComponent,
)
