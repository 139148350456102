import { ActionReducerMapBuilder, AsyncThunk } from '@reduxjs/toolkit'
import { cmsInitialState } from '../../reducers/rmsCmsReducer'
import { fetchFlatMenu } from '../actions/fetchFlatMenu'
import createAPIActionReducerLogic from '../../../api/utils/createAPIActionReducerLogic'

export const rmsCmsFlatMenuReducerLogic = (
  builder: ActionReducerMapBuilder<typeof cmsInitialState>,
) => {
  createAPIActionReducerLogic<typeof fetchFlatMenu, typeof cmsInitialState>(
    builder,
    fetchFlatMenu,
    state => state.footerMenu,
    (state, newValue) => (state.footerMenu = newValue),
  )
}
