import { HomeHeaderBlockModel } from './HomeHeaderBlockModel'
import styles from './HomeHeaderBlock.module.scss'
import multipleClassName from '../../../../utils/multipleClassName'
import { Parallax, ParallaxBanner } from 'react-scroll-parallax'
import trackEvent from '../../../../features/rms-matomo/track-event'
import RMSImageMultiSizeElement from '../../components/elements/rms-image-multi-size-element/RMSImageMultiSizeElement'
import rmsCmsImageUrl from '../../../../features/cms/utils/rmsCmsImageUrl'
import { Link } from 'react-router-dom'
import DHRichTextElement from '../../components/elements/dh-rich-text-element/DHRichTextElement'
import parse from 'html-react-parser'
import { scrollToElement } from '../../../../utils/scrollHelper'
import { useEffect } from 'react'
import { getCorrectImage } from '../../components/elements/rms-image-multi-size-element/utils/rmsMultiSizeImg'
import classNames from 'classnames'

export default function HomeHeaderBlock({
  blockElement,
}: {
  blockElement: HomeHeaderBlockModel
}) {
  const blockValue = blockElement.value

  function scrollToNextSection() {
    trackEvent({
      category: 'Button',
      action: 'Click',
      name: 'Scroll To Next Section',
    })

    // Get the home header block
    const homeHeaderBlock = document.getElementById('home-header-block')
    if (!homeHeaderBlock) {
      return
    }

    // Get the next section after the home header block
    const nextSection = homeHeaderBlock.nextSibling as HTMLElement

    // Scroll to the next section
    if (nextSection) {
      scrollToElement(nextSection)
    }
  }

  const getBackgroundImage = (): string => {
    const image = getCorrectImage(blockValue.background_image)

    if (image === null) return ''
    else return image.image.image ?? ''
  }

  return (
    <>
      <section
        id="home-header-block"
        className={multipleClassName(styles.section, 'IgnoreMaxWidth')}
      >
        <div className={styles.container}>
          <ParallaxBanner
            layers={[
              {
                image: rmsCmsImageUrl(getBackgroundImage()),
                speed: 20,
                translateY: [0, 0],
                translateX: [-12.5, 12.5],
                style: {
                  scale: '125%',
                  objectFit: 'cover',
                  translate: '-12.5% 0',
                },
              },
            ]}
            className={styles.background}
            style={{ position: 'absolute' }}
          />
          <div className={styles.content}>
            <h1 className={styles.header} data-aos="fade-up">
              {blockValue.title}
            </h1>

            <p
              className={styles.subheader}
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {blockValue.subtitle}
            </p>

            {blockValue.header_content && (
              <div
                className={styles.content}
                data-aos="fade-up"
                data-aos-delay="300"
              >
                {parse(blockValue.header_content)}
              </div>
            )}

            <div className={styles.buttons}>
              {blockValue.show_more_text && (
                <a
                  onClick={scrollToNextSection}
                  className={classNames(styles.button, 'custom')}
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  {blockValue.show_more_text}
                  <svg
                    className="w-5 h-5 ml-2 -mr-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              )}

              {/* // TODO: load second button text and link dynamically from backend */}
              <Link
                to="/kontakt"
                className={classNames(styles.secondaryButton, 'custom')}
                data-aos="fade-up"
                data-aos-delay="600"
              >
                Termin vereinbaren
              </Link>
            </div>
          </div>

          {blockValue.image !== undefined && (
            <div
              className={styles.imageContainer}
              data-aos="fade-left"
              data-aos-delay="800"
            >
              <RMSImageMultiSizeElement
                imageMultiSize={blockValue.image!}
                className={styles.image}
                disableClick
                style={{ objectFit: 'contain', width: 'auto', height: 'auto' }}
              />
            </div>
          )}
        </div>
      </section>
    </>
  )
}
