import styles from './ContactElement.module.scss'
import multipleClassName from '../../../../../../../utils/multipleClassName'
import { Link } from 'react-router-dom'

export default function ContactElement({
  iconClassName,
  text,
  link,
  className,
}: {
  iconClassName: string
  text?: string
  link?: string
  className?: string
}) {
  if (!link || !text) {
    return null
  }

  return (
    <Link
      to={link!}
      className={multipleClassName(styles.ContactElement, className)}
    >
      <i className={iconClassName}></i> {text}
    </Link>
  )
}
