import { AxiosRequestConfig } from 'axios'
import RMSApiConfig from './models/RMSApiConfig'

export function mapAxios(config: RMSApiConfig): AxiosRequestConfig {
  return {
    ...config,
    baseURL: config.baseURL,
    headers: config.headers,
    params: config.params,
    data: config.data,
    timeout: config.timeout,
    transformResponse: config.responseInterceptors,
    transformRequest: config.requestInterceptors,
    beforeRedirect: config.beforeRedirect,
  }
}
