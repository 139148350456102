import { RMSMultiSizeImageBlockModel } from './RMSMultiSizeImageBlockModel'
import styles from './RMSMultiSizeImageBlock.module.scss'
import { DownloadElement } from '../../components/elements/download-element/DownloadElement'
import RMSImageMultiSizeElement from '../../components/elements/rms-image-multi-size-element/RMSImageMultiSizeElement'

export default function RMSMultiSizeImageBlock({
  blockElement,
}: {
  blockElement: RMSMultiSizeImageBlockModel
}) {
  return (
    <div className={styles.RMSMultiSizeImageBlock}>
      <RMSImageMultiSizeElement imageMultiSize={blockElement.value} />
    </div>
  )
}
