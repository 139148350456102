import React, { useEffect } from 'react'
import { RMSPartnersBlockModel } from './RMSPartnersBlockModel'
import styles from './RMSPartnersBlock.module.scss'
import classNames from 'classnames'
import transformToReactStyle from '../../components/elements/rms-css-style-block/transformToReactStyle'
import { motion } from 'framer-motion'
import { configOpacitySlideUp } from '../../animations/framer-motion/motion-configs'
import RMSPartnerCard from '../../../../features/rms-projects/features/partners/components/RMSPartnerCard'

export default function RMSPartnersBlock({
  blockElement,
}: {
  blockElement: RMSPartnersBlockModel
}): React.JSX.Element {
  return (
    <section
      className={classNames(
        styles.section,
        blockElement.value.css_style?.additional_classes,
      )}
      style={transformToReactStyle(blockElement.value.css_style)}
    >
      <div className={styles.content}>
        <div className={classNames(styles.inner)}>
          {blockElement.value.title && (
            <motion.h2 {...configOpacitySlideUp} className={styles.title}>
              {blockElement.value.title}
            </motion.h2>
          )}

          {blockElement.value.subtitle && (
            <motion.p
              {...configOpacitySlideUp}
              transition={{
                ...configOpacitySlideUp.transition,
                delay: 0.2,
              }}
              className={styles.subtitle}
            >
              {blockElement.value.subtitle}
            </motion.p>
          )}

          <div className={classNames(styles.partners)}>
            {blockElement.value.partners?.map((partner, index) => (
              <motion.div
                key={index}
                className={styles.partner}
                {...configOpacitySlideUp}
                transition={{
                  ...configOpacitySlideUp.transition,
                  delay: (index + 1) * 0.2 + 0.2,
                }}
              >
                <RMSPartnerCard partner={partner} />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
