import styles from './MainFooter.module.scss'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useRMSApiConfig } from '../../../../features/api/contexts/RMSApiContext'
import { useEffect } from 'react'
import { fetchFlatMenu } from '../../../../features/cms/menus/actions/fetchFlatMenu'
import { useFlatMenuWithState } from '../../../../features/cms/hooks/useFlatMenuSelector'
import * as CookieConsent from 'vanilla-cookieconsent'
import spitzerLogo from '../../../../../assets/images/spitzer_logo.jpg'
import classNames from 'classnames'

function MenuItem({ name = 'Menu Item', menuLink = '/', clickHandler = null }) {
  const handleClick = (event: any) => {
    // If a clickHandler is provided, call it
    if (clickHandler) {
      event.preventDefault()
      ;(clickHandler as any)()
    }

    // You can also include other logic here if needed
    // <button type="button" data-cc="show-preferencesModal">Cookies</button>
  }

  return (
    <Link
      to={menuLink}
      className={classNames(styles.element, 'custom')}
      onClick={handleClick}
    >
      {name}
    </Link>
  )
}

export default function MainFooter() {
  const dispatch = useDispatch()
  const apiConfig = useRMSApiConfig()
  const {
    footerMenu,
    loadingState: menuLoadingState,
    error: menuError,
  } = useFlatMenuWithState()

  useEffect(() => {
    dispatch(
      fetchFlatMenu({ idOrHandle: 'footer', apiConfig: apiConfig }) as any,
    )
  }, [dispatch])

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.imageContainer}>
            <Link to="/" className={classNames(styles.link, 'custom')}>
              <img
                src={spitzerLogo}
                className={styles.image}
                alt="Ordination Logo"
              />
              <span className={styles.text}>Zahnarzt Dr. Spitzer</span>
            </Link>

            <ul className={styles.list}>
              {footerMenu &&
                footerMenu.menu_items.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      name={item.menu_text}
                      menuLink={item.path}
                    />
                  )
                })}
              {menuLoadingState === 'loading' && <div>Lade...</div>}
              {menuError && <div>Fehler beim Laden des Menüs</div>}
              <MenuItem
                name="Cookies"
                menuLink="/"
                clickHandler={
                  (() => {
                    CookieConsent.showPreferences()
                  }) as any
                }
              />
            </ul>
          </div>

          <hr className={styles.hr} />

          <span className={styles.bottom}>
            © {new Date().getFullYear()}{' '}
            <Link
              to="https://rm-softwares.at/"
              target="_blank"
              className={styles.element}
            >
              RM Softwares
            </Link>
          </span>
        </div>
      </footer>
    </>
  )
}
