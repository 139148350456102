import styles from './RMSPageMetadata.module.scss'
import { RMSPageModel } from '../../../pages/RMSPage/RMSPageModel'
import RMSBreadcrumb from '../rms-breadcrumb/RMSBreadcrumb'
import React, { useCallback } from 'react'

export default function RMSPageMetadata({ page }: { page: RMSPageModel }) {
  const getFormattedDate = useCallback(() => {
    if (page.date) {
      const date = new Date(page.date)
      return date.toLocaleDateString()
    }
    return ''
  }, [page.date])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {page.show_breadcrumbs &&
          page.breadcrumb_parts &&
          page.breadcrumb_parts.length > 0 && (
            <div className={styles.breadcrumb}>
              <RMSBreadcrumb
                breadcrumb_parts={page.breadcrumb_parts.filter(
                  value => value.title !== 'Root',
                )}
              />
            </div>
          )}
        {page.show_date && (
          <div className={styles.date}>
            Veröffentlicht am {getFormattedDate()}
          </div>
        )}
      </div>
    </div>
  )
}
