import { EmployeePageModel } from './EmployeePageModel'
import styles from './EmployeePage.module.scss'
import DHContentBlock from '../../blocks/dh-content-block/DHContentBlock'
import useBackgroundColor from '../../../hooks/style/useBackgroundColor'
import DefaultPageContainer from '../../components/containers/default-page-container/DefaultPageContainer'
import rmsCmsImageUrl from '../../../../features/cms/utils/rmsCmsImageUrl'
import multipleClassName from '../../../../utils/multipleClassName'
import { Link } from 'react-router-dom'
import LeftRightFloatingContainer from '../../components/containers/left-right-floating-container/LeftRightFloatingContainer'
import RMSTechnologiesContainer from '../../../../features/rms-projects/features/technologies/components/rms-technologies-container/RMSTechnologiesContainer'
import RMSEmployeeProfilePicture from '../../../../features/rms-projects/features/employee/components/rms-employee-profile-picture/RMSEmployeeProfilePicture'
import CVSection from '../../../../features/rms-projects/features/employee/components/cv/cv-section/CVSection'
import CVBlock from '../../../../features/rms-projects/features/employee/components/cv/cv-block/CVBlock'
import RatingHorizontalContainer from '../../../../features/rms-projects/features/general/components/containers/rating-horizontal-container/RatingHorizontalContainer'
import RMSTechnologiesRatedContainer from '../../../../features/rms-projects/features/technologies/components/rms-technologies-rated-container/RMSTechnologiesRatedContainer'
import { motion } from 'framer-motion'
import { configOpacityScale } from '../../animations/framer-motion/motion-configs'
import ContactElement from '../../../../features/rms-projects/features/general/components/elements/contact-element/ContactElement'

export default function EmployeePage({ page }: { page: EmployeePageModel }) {
  const employee = page.employee

  if (page.employee.custom_design) {
    return (
      <DefaultPageContainer className={styles.EmployeePage}>
        <DHContentBlock blocks={page.employee.content} />
      </DefaultPageContainer>
    )
  }

  return (
    <DefaultPageContainer
      title={page.employee.first_name + ' ' + page.employee.last_name}
      className={styles.EmployeePage}
    >
      <RMSTechnologiesContainer
        technologies={page.employee.technologies?.map(tech => tech.technology)}
        className={styles.TechnologiesContainer}
      />
      <div className={styles.SubHeaderContainer}>
        <ContactElement
          iconClassName={'bi bi-telephone-fill'}
          text={employee.phone_number}
          link={'tel:' + employee.phone_number}
          className={styles.ContactElement}
        />
        <ContactElement
          iconClassName={'bi bi-envelope-at-fill'}
          text={employee.email}
          link={'mailto:' + employee.email}
          className={styles.ContactElement}
        />
        <ContactElement
          iconClassName={'bi bi-linkedin'}
          text="LinkedIn"
          link={employee.linkedin}
          className={styles.ContactElement}
        />
        <ContactElement
          iconClassName={'bi bi-github'}
          text="Github"
          link={employee.github}
          className={styles.ContactElement}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4">
        {page.employee.description && (
          <div
            className="md:col-span-3 md:me-4"
            dangerouslySetInnerHTML={{ __html: page.employee.description }}
          ></div>
        )}
        {page.employee.profile_picture && (
          <>
            <RMSEmployeeProfilePicture employee={page.employee} />
          </>
        )}
      </div>
      {page.employee.content && (
        <DHContentBlock blocks={page.employee.content} />
      )}
    </DefaultPageContainer>
  )
}
