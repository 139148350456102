import { RMSPagePreviewBlockModel } from './RMSPagePreviewBlockModel'
import styles from './RMSPagePreviewBlock.module.scss'
import multipleClassName from '../../../../utils/multipleClassName'
import transformToReactStyle, {
  transformToReactMaxWidthContainerStyle,
} from '../../components/elements/rms-css-style-block/transformToReactStyle'
import transformToReactContainerClasses from '../../components/elements/rms-css-style-block/transformToReactClasses'
import RMSPagePreviewElement from '../../components/elements/rms-page-preview-element/RMSPagePreviewElement'
import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { configOpacitySlideUp } from '../../animations/framer-motion/motion-configs'

export default function RMSPagePreviewBlock({
  blockElement,
}: {
  blockElement: RMSPagePreviewBlockModel
}) {
  const data = blockElement.value

  return (
    <div
      className={multipleClassName(
        styles.RMSPagePreviewBlock,
        blockElement.value.css_style?.additional_classes,
      )}
      style={transformToReactStyle(blockElement.value.css_style)}
    >
      <div
        className={multipleClassName(
          styles.inner,
          'MaxWidthContainer',
          transformToReactContainerClasses(blockElement.value.css_style),
        )}
        style={transformToReactMaxWidthContainerStyle(
          blockElement.value.css_style,
        )}
      >
        <div className={styles.IndexContainer}>
          {data.pages!.map((child, index) => {
            return <RMSPagePreviewElement key={index} pagePreview={child} />
          })}
        </div>

        <motion.div
          {...configOpacitySlideUp}
          className={styles.buttonContainer}
        >
          <Link to={data.parent_page ?? ''}>
            <button
              className={classNames('primary rms-clickable', styles.button)}
            >
              {data.parent_page_show_all_text}
            </button>
          </Link>
        </motion.div>
      </div>
    </div>
  )
}
