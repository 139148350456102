import { useRef } from 'react'
import RMSImageMultiSize, { RMSImageMultiSizeItem } from '../RMSImageMultiSize'

const getCorrectImage = (
  imageMultiSize: RMSImageMultiSize | undefined,
): RMSImageMultiSizeItem | null => {
  const innerWidth = window.innerWidth

  const windowSize = window.innerWidth

  if (imageMultiSize === undefined) {
    return null
  }

  if (imageMultiSize.image_versions.length === 0) {
    return null
  }

  if (imageMultiSize.image_versions.length < 2) {
    return imageMultiSize.image_versions[0]
  }

  return imageMultiSize.image_versions.reduce((previousValue, currentValue) => {
    const previousDiff = previousValue.width - windowSize
    const currentDiff = currentValue.width - windowSize

    if (currentDiff < 0) {
      // use the biggest version, if the previous one is below the current windowSize
      return currentDiff > previousDiff ? currentValue : previousValue
    } else {
      // otherwise use the closest version to 0 (best value)
      if (previousDiff < 0) {
        return currentValue
      }
      return currentDiff < previousDiff ? currentValue : previousValue
    }
  })
}

export { getCorrectImage }
