import classNames from 'classnames'
import styles from './RMSDualSplitBlock.module.scss'
import { RMSDUalSplitBlockModel } from './RMSDualSplitBlockModel'
import transformToReactStyle from '../../components/elements/rms-css-style-block/transformToReactStyle'
import { configOpacitySlideUp } from '../../animations/framer-motion/motion-configs'
import { useEffect } from 'react'
import DHContentBlock from '../dh-content-block/DHContentBlock'

export default function RMSDualSplitBlock({
  blockElement,
}: {
  blockElement: RMSDUalSplitBlockModel
}) {
  const data = blockElement.value

  return (
    <section
      className={classNames(
        styles.section,
        blockElement.value.css_style?.additional_classes,
      )}
      style={transformToReactStyle(blockElement.value.css_style)}
    >
      <div className={styles.content}>
        <div className={styles.left}>
          <DHContentBlock blocks={data.left_block ? data.left_block : []} />
        </div>
        <div className={styles.right}>
          <DHContentBlock blocks={data.right_block ? data.right_block : []} />
        </div>
      </div>
    </section>
  )
}
