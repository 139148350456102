import { RMSServicesBlockModel } from './RMSServicesBlockModel'
import styles from './RMSServicesBlock.module.scss'
import transformToReactStyle, {
  transformToReactMaxWidthContainerStyle,
} from '../../components/elements/rms-css-style-block/transformToReactStyle'
import transformToReactContainerClasses from '../../components/elements/rms-css-style-block/transformToReactClasses'
import { motion } from 'framer-motion'
import {
  configOpacityScale,
  configOpacitySlideLeft,
  configOpacitySlideUp,
} from '../../animations/framer-motion/motion-configs'
import RMSServiceSmall from '../../../../features/rms-projects/features/services/components/rms-service-small/RMSServiceSmall'
import { useEffect } from 'react'
import classNames from 'classnames'
import { delay } from '@reduxjs/toolkit/dist/utils'
import RMSCard from '../../components/elements/rms-card/RMSCard'
import rmsCmsImageUrl from '../../../../features/cms/utils/rmsCmsImageUrl'

export default function RMServicesBlock({
  blockElement,
}: {
  blockElement: RMSServicesBlockModel
}) {
  const data = blockElement.value

  const renderServicesRow = (providedIndex: number): React.JSX.Element => {
    let remainingCols = (data.services?.length ?? 0) - providedIndex

    if (remainingCols === 0) {
      return <></>
    }

    return (
      <div
        key={`service-row-${providedIndex}`}
        className={classNames(styles.cardRow, {
          [styles.cardRow1]: remainingCols <= 1,
          [styles.cardRow2]: remainingCols === 2,
          [styles.cardRow3]: remainingCols >= 3,
        })}
      >
        {data.services?.map((service, index) => {
          if (index >= providedIndex && index < providedIndex + 3) {
            return (
              <motion.div
                key={`service-${index}`}
                className={styles.card}
                {...configOpacitySlideUp}
                transition={{
                  ...configOpacitySlideUp.transition,
                  delay: (index - providedIndex + 1) * 0.2 + 0.2,
                }}
              >
                <RMSCard
                  title={service.name ?? ''}
                  text={service.short_description ?? ''}
                  buttonText="Mehr erfahren"
                  image={rmsCmsImageUrl(service.title_picture ?? '')}
                  to={'/services/' + service.slug}
                />
              </motion.div>
            )
          }
        })}
      </div>
    )
  }

  const renderServices = (): React.JSX.Element[] => {
    const renderedServices: React.JSX.Element[] = []

    for (let i = 0; i < (data.services?.length ?? 0); i += 3) {
      renderedServices.push(renderServicesRow(i))
    }

    return renderedServices
  }

  return (
    <section
      className={classNames(
        styles.section,
        blockElement.value.css_style?.additional_classes,
      )}
      style={transformToReactStyle(blockElement.value.css_style)}
    >
      <div className={styles.content}>
        <div className={classNames(styles.inner)}>
          {data.title && (
            <motion.h2 {...configOpacitySlideUp} className={styles.title}>
              {data.title}
            </motion.h2>
          )}

          {data.subtitle && (
            <motion.p
              {...configOpacitySlideUp}
              transition={{
                ...configOpacitySlideLeft.transition,
                delay: 0.2,
              }}
              className={styles.subtitle}
            >
              {data.subtitle}
            </motion.p>
          )}

          <div className={classNames(styles.cards)}>{renderServices()}</div>
        </div>
      </div>
    </section>
  )
}
