import React from 'react'
import styles from './RMSQuoteBlock.module.scss'
import { RMSQuoteBlockModel } from './RMSQuoteBlockModel'
import parse from 'html-react-parser'
import classNames from 'classnames'
import { useRmsId } from '../../../../utils/useId'

export default function RMSQuoteBlock({
  blockElement,
}: {
  blockElement: RMSQuoteBlockModel
}) {
  const id = useRmsId()

  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <figure className={styles.figure} id={id}>
          <svg
            className={classNames(styles.svg, styles.top)}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 14"
            data-aos="fade-up"
            data-aos-anchor={`#${id}`}
          >
            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
          </svg>
          <blockquote>
            <p
              className={styles.blockquote}
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-anchor={`#${id}`}
            >
              {parse(blockElement.value?.rich_text)}
            </p>
          </blockquote>
          <svg
            className={classNames(styles.svg, styles.bottom)}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 14"
            data-aos="fade-down"
            data-aos-anchor={`#${id}`}
            data-aos-delay="400"
          >
            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
          </svg>
        </figure>
      </div>
    </section>
  )
}
