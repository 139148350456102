import styles from './LeftRightFloatingContainer.module.scss'
import multipleClassName from '../../../../../utils/multipleClassName'
export default function LeftRightFloatingContainer({
  children,
  floatingChildren,
  left,
  floatingContainerClassName,
  contentClassName,
}: {
  children: React.ReactNode
  floatingChildren: React.ReactNode
  left?: boolean
  floatingContainerClassName?: string
  contentClassName?: string
}) {
  return (
    <>
      <div
        className={multipleClassName(
          styles.FloatingContainer,
          floatingContainerClassName,
          left ? styles.Left : styles.Right,
        )}
      >
        {floatingChildren}
      </div>
      <div className={multipleClassName(styles.Content, contentClassName)}>
        {children}
      </div>
    </>
  )
}
