import axios from 'axios'
import { mapAxios } from './mappers'
import RMSApiConfig from './models/RMSApiConfig'

export default class RMSApi {
  public static async get(url: string, config?: RMSApiConfig): Promise<any> {
    return axios.get(url, mapAxios(config ?? {}))
  }

  public static async post(
    url: string,
    data?: any,
    config?: RMSApiConfig,
  ): Promise<any> {
    return axios.post(url, data, mapAxios(config ?? {}))
  }
}
